<template>
  <v-container>
    <v-row>
      <v-col>
        <work-in-progress-card/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WorkInProgressCard from "@/components/WorkInProgressCard";
export default {
  name: "HomeView",
  components: {WorkInProgressCard}
}
</script>

<style scoped>

</style>