<template>
  <v-app
      id="app"
      app
  >
    <v-app-bar
        id="app-bar"
        app
    >
      <v-toolbar-title
          id="app-bar-title-logo"
      >
        <v-img
            id="app-bar-logo"
            contain
            max-height="90"
            max-width="90"
            src="./assets/logo.png"
        />
      </v-toolbar-title>
      <v-toolbar-title
          id="app-bar-title"
      >
        Nathan Vicen Dev
      </v-toolbar-title>
    </v-app-bar>

    <v-main
        id="app-main"
    >
      <HomeView/>
    </v-main>
  </v-app>
</template>

<script>
import HomeView from "@/views/HomeView";

export default {
  name: 'App',
  components: {
    HomeView,
  },
  mounted() {
    document.title = "Nathan Vicen Dev";
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
